import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from './../../environments/environment';
import {catchError} from 'rxjs/operators';
import {User} from '../models/eventRequest';

const AUTH_API = environment.APIEndpoint + '/api/auth/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) { }

  login(username: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'signin', {
      username,
      password
    }, httpOptions);
  }

  register(username: string, email: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'signup', {
      username,
      email,
      password
    }, httpOptions);
  }

  updateUserPassword(userId: any, user: User, isStudent: boolean): Observable<any> {
    if (userId) {
      return this.http.put(AUTH_API + 'users/' + userId + '?isStudent=' + isStudent, user, httpOptions);
    }
    return this.http.post(AUTH_API + 'users' + '?isStudent=' + isStudent, user, httpOptions);
  }

  getUserByEmail(email: string | undefined): Observable<any> {
    return this.http.get(AUTH_API + 'users?email=' + email, httpOptions);
  }

  public isAdmin() : Observable<boolean> {
    return this.http.get<boolean>(AUTH_API + 'isAdmin', httpOptions)
        .pipe(
          catchError(error => {
            return throwError(error);
          })
        );
  }

}

export interface FooInterface {
  status: string;
  fooString : string;
  fooNumber : number;
}
