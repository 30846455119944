import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../_services/auth.service';
import {Monitor, Student, User} from '../../models/eventRequest';
import * as moment from 'moment';

@Component({
  selector: 'app-user-update-pwd',
  templateUrl: './user-update-pwd.component.html',
  styleUrls: ['./user-update-pwd.component.css']
})
export class UserUpdatePwdComponent implements OnInit {

  form: any = {
    username: null,
    email: null,
    password: null
  };

  email?: string;

  errorMessage: string = "";
  isSignUpFailed = false;
  user?: User;
  userId = null;
  isSuccessful: boolean = false;

  @Input()
  iStudent?: Student;

  @Input()
  iMonitor?: Monitor;

  popupError: any;

  constructor(private fb:UntypedFormBuilder, private authService: AuthService) {

  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.iStudent) {
      this.email = this.iStudent.email;
    } else if (this.iMonitor) {
      this.email = this.iMonitor.email;
    }
    this.authService.getUserByEmail(this.email).subscribe(
      data => {
        this.user = data;
        this.userId = data.id;
        this.isSignUpFailed = true;
        this.form = {
          username: this.user?.username,
          email: this.user?.email,
          password: null
        };
      },
      err => {
        this.errorMessage = err.error.message;
        this.isSignUpFailed = false;
        this.form = {
          username: null,
          email: this.email,
          password: null
        };
      }
    );
  }

  onSubmit(): void {
    this.authService.updateUserPassword(this.userId, this.form, this.iStudent? true : false).subscribe(
      data => {
        this.isSuccessful = true;
      },
      err => {
        this.errorMessage = err.message;
      }
    );
  }

}
